import { Injectable } from '@angular/core';
import { UiPreferencesService } from '@proman/services/ui-preferences.service';
import { Entity } from '@proman/services/entity.service';
import { prepareRequest } from '@proman/utils';
import { pick } from 'lodash';
import { FilterService } from '@proman/services/filter.service';
import { ToastService } from '@proman/services/toast.service';
import { PromanStateService } from '@frontend/shared/services/proman-state.service';
import { SaleOpportunity } from '@proman/interfaces/entity-interfaces';
import { Dialog } from '@frontend/shared/services/dialog.service';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class SaleOpportunitiesService {

    public statuses: string[] = [
        'active',
        'won',
        'archived',
        'lost'
    ];
    customersQuery: any = { sort: { name: 'asc' } };

    saleOpportunityEntity: any;

    constructor(
        private promanState: PromanStateService,
        private Entity: Entity,
        private Filter: FilterService,
        private Toast: ToastService,
        private Dialog: Dialog,
        private UiPrefs: UiPreferencesService,
    ) {
        this.saleOpportunityEntity = this.Entity.get('sale_opportunity');
    }

    archive = (item: any) => {
        return new Promise((resolve) => {
            resolve(
                this.saleOpportunityEntity
                    .update({
                        id: item.id,
                        status: this.statuses[2]
                    })
                    .then(() => {
                        this.Toast.pop('success', 'moved_to_archive');
                        this.promanState.to('SaleOpportunities');
                    })
            )
        });
    };

    createOrder = (item: SaleOpportunity, newTab?: boolean) => {
        let data: any = prepareRequest(pick(item, ['name', 'manager', 'customer']));

        data.managerComments = item.comments;
        data.title = `${item.id} - ${item.name} - ${this.Filter.date(moment())}`;
        data.sale = item.id;

        return this.Entity.get('order_proposal')
            .create(data)
            .then((response: any) => {
                this.UiPrefs.set('activeOrderProposal', response.data);

                this.promanState.to('OrderCreate', null, { saleOpportunityId: item.id }, newTab);
            });
    };

    getStatuses() {
        let formattedStatuses = [];

        for (let status of this.statuses) {
            formattedStatuses.push({
                id: status,
                name: this.Filter.translate(status),
                type: status
            });
        }

        return formattedStatuses;
    };

}
